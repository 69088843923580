import React from 'react'

import Layout from 'components/layout'
import { sectionListDocs } from 'utils/sidebar/section-list'

const DocsIndexPage = ({ location }) => (
  <Layout location={location} showSidebar={true} sectionList={sectionListDocs}>
    <h2>Docs home page</h2>
  </Layout>
)

export default DocsIndexPage
